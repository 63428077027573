import { AppState } from 'root.reducer';
import axiosLib, { CancelTokenSource } from 'axios';
import { StateController } from 'utils/action-declaration';
import { StaffProfileService } from 'api/staff/staff-profile/staff-profile.service';
import CommonService from 'api/common/common.service';
import { AreaService } from 'api/area/area.service';
import {
    SaveProfileHighlightItem,
    StaffProfileModel,
    QualificationSelectItem,
    CoachingLicensesSelectItem,
    SkillsSelectItem,
    LanguagesSelectItem,
    SaveProfileCareerItem,
    StaffCareerDraftItemDtoExtended,
} from 'api/staff/staff-profile/model';
import { getIsPersonalAccount } from 'store/auth/authReducer';
import { getCancelTokenSource } from 'axios-config';
import { createImage, getRadianAngle, rotateSize } from 'utils/blob-image-path';
import { notificationCreate } from 'app/notifications/notifications.actions';
import { PERMISSIONS_PERSONAL_ACCOUNT_UPDATE } from 'store/actionTypes';
import { getPersonalAccountUserPermissions } from 'store/userPreference/userPreference.reducer';
import { cloneDeep } from 'lodash';
import { NationalitySelectItem, LocationsSelectItem } from 'api/core/area-short-info';
import { convertToUtcDate, getIsDateInFuture } from 'utils/date';
import moment from 'moment';
import { ROLE_MAX_LENGTH } from 'pages/staff/constants/staff-forms';

export type EditBannerModalData = {
    isOpen: boolean;
    bannerImageServerPath: string;
    bannerImage: string;
    bannerImageName: string;
    bannerImageSize: number;
    bannerImageUploadProgress: number;
    bannerImageValidationErrors: string;
};

export type EditProfileImageModalData = {
    isOpen: boolean;
    profileImageServerPath: string;
    profileImage: string;
    profileImageName: string;
    profileImageSize: number;
    profileImageUploadProgress: number;
    profileImageValidationErrors: string;
};

export type EditNameModalData = {
    isOpen: boolean;
    firstName: string;
    lastName: string;
};

export type EditJobTitleModalData = {
    isOpen: boolean;
    team: string;
    jobTitle: string;
    squadId: number | null;
};

export type EditAgeModalData = {
    isOpen: boolean;
    birthDate: string | Date | null;
    isAgeVisible: boolean;
};

export type EditAboutModalData = {
    isOpen: boolean;
    aboutText: string;
};

export type EditPersonalSummaryModalData = {
    isOpen: boolean;
    nationality: NationalitySelectItem[];
    languages: LanguagesSelectItem[];
    locations: LocationsSelectItem[];
};

export type EditHighlightsModalData = {
    isOpen: boolean;
    highlights: SaveProfileHighlightItem[];
};

export type EditKeySkillsModalData = {
    isOpen: boolean;
    qualifications: QualificationSelectItem[];
    coachingLicense: CoachingLicensesSelectItem | null;
    skills: SkillsSelectItem[];
};

export type EditCareerModalData = {
    isOpen: boolean;
    careerCurrent: StaffCareerDraftItemDtoExtended[];
    careerChanged: StaffCareerDraftItemDtoExtended[];
    newCareerItem: StaffCareerDraftItemDtoExtended | null;
};

export type EditCareerCancelAlertModalData = {
    isOpen: boolean;
};

export type EditCareerDeleteModalData = {
    isOpen: boolean;
    deleteItem: StaffCareerDraftItemDtoExtended;
};

export interface HighlightsModalValidation {
    isTitleSpecified: boolean;
    isDescriptionSpecified: boolean;
}

class State {
    isLoading: boolean;
    isProcessing: boolean;
    profile: StaffProfileModel;
    editBannerModal: EditBannerModalData;
    editProfileImageModal: EditProfileImageModalData;
    editNameModal: EditNameModalData;
    editJobTitleModal: EditJobTitleModalData;
    editAgeModal: EditAgeModalData;
    editAboutModal: EditAboutModalData;
    editPersonalSummaryModal: EditPersonalSummaryModalData;
    editHighlightsModal: EditHighlightsModalData;
    editKeySkillsModal: EditKeySkillsModalData;
    nationalitiesList: NationalitySelectItem[];
    languagesList: LanguagesSelectItem[];
    locationsList: LocationsSelectItem[];
    coachingLicensesList: CoachingLicensesSelectItem[];
    skillsList: SkillsSelectItem[];
    isOwnProfile: boolean;
    editCareerModal: EditCareerModalData;
    editCareerCancelAlertModal: EditCareerCancelAlertModalData;
    editCareerDeleteModal: EditCareerDeleteModalData;
}

const defaultState: State = {
    isLoading: false,
    isProcessing: false,
    profile: null,
    editBannerModal: {
        isOpen: false,
        bannerImageServerPath: null,
        bannerImage: null,
        bannerImageName: null,
        bannerImageSize: null,
        bannerImageUploadProgress: null,
        bannerImageValidationErrors: null,
    },
    editProfileImageModal: {
        isOpen: false,
        profileImageServerPath: null,
        profileImage: null,
        profileImageName: null,
        profileImageSize: null,
        profileImageUploadProgress: null,
        profileImageValidationErrors: null,
    },
    editNameModal: {
        isOpen: false,
        firstName: '',
        lastName: '',
    },
    editJobTitleModal: {
        isOpen: false,
        squadId: null,
        team: '',
        jobTitle: '',
    },
    editAgeModal: {
        isOpen: false,
        birthDate: null,
        isAgeVisible: true,
    },
    editAboutModal: {
        isOpen: false,
        aboutText: '',
    },
    editPersonalSummaryModal: {
        isOpen: false,
        nationality: [],
        languages: [],
        locations: [],
    },
    editHighlightsModal: {
        isOpen: false,
        highlights: [],
    },
    editKeySkillsModal: {
        isOpen: false,
        qualifications: [],
        coachingLicense: null,
        skills: [],
    },
    nationalitiesList: [],
    languagesList: [],
    locationsList: [],
    coachingLicensesList: [],
    skillsList: [],
    isOwnProfile: false,
    editCareerModal: {
        isOpen: false,
        careerCurrent: [],
        careerChanged: [],
        newCareerItem: null,
    },
    editCareerCancelAlertModal: {
        isOpen: false,
    },
    editCareerDeleteModal: {
        isOpen: false,
        deleteItem: null,
    },
};

const stateController = new StateController<State>('STAFF_PROFILE/MAIN_INFO', defaultState);

class Actions {
    public static cancelToken: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static init(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await dispatch(Actions.loadProfile(staffId));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static initCurrent() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                await dispatch(Actions.loadCurrentProfile());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
            dispatch(Actions.loadDictionaries());
        };
    }

    public static loadDictionaries() {
        return (dispatch, getState: () => AppState) => {
            try {
                dispatch(Actions.getNationalitiesList());
                dispatch(Actions.getLanguagesList());
                dispatch(Actions.getLocationsList());
                dispatch(Actions.getCoachingLicensesList());
                dispatch(Actions.getSkillsList());
            } catch (err) {
                console.error(err);
            } finally {
            }
        };
    }

    public static loadCurrentProfile() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await StaffProfileService.getCurrentProfile();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        profile: {
                            ...data,
                            nationalities: data.nationalities.map((item) => ({
                                ...item,
                                ico: item.flagPath,
                                name: item.nationalityName,
                            })),
                            career: data.career.map((item, index) => ({
                                ...item,
                                key: `${item.role}-${index}`,
                            })),
                            playingCareer: data.playingCareer.map((item, index) => ({
                                ...item,
                                key: `${item.role}-${index}`,
                            })),
                            references: data.references.map((item, index) => ({
                                ...item,
                                key: `${item.role}-${index}`,
                            })),
                        },
                        editBannerModal: {
                            ...prevState.editBannerModal,
                            bannerImageServerPath: data.generalInfo.backgroundImage,
                        },
                        editProfileImageModal: {
                            ...prevState.editProfileImageModal,
                            profileImageServerPath: data.generalInfo.photo,
                        },
                        isOwnProfile: true,
                    }))
                );
            } catch (err) {
                console.error(err);
            }
        };
    }

    public static loadProfile(staffId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await StaffProfileService.getProfile(staffId);
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        profile: {
                            ...data,
                            nationalities: data.nationalities?.map((item) => ({
                                ...item,
                                ico: item.flagPath,
                                name: item.nationalityName,
                            })),
                            career: data.career?.map((item, index) => ({
                                ...item,
                                key: `${item.role}-${index}`,
                            })),
                            playingCareer: data.playingCareer?.map((item, index) => ({
                                ...item,
                                key: `${item.role}-${index}`,
                            })),
                            references: data.references?.map((item, index) => ({
                                ...item,
                                key: `${item.role}-${index}`,
                            })),
                        },
                        isOwnProfile: false,
                    }))
                );
            } catch (err) {
                console.error(err);
            }
        };
    }

    public static openStaffProfileByStaffId(staffId: number) {
        return (dispatch, getState: () => AppState) => {
            window.open('/staff-profile/' + staffId, '_blank');
        };
    }

    // EDIT BANNER MODAL START

    public static openEditBannerModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editBannerModal: {
                        ...prevState.editBannerModal,
                        isOpen: true,
                    },
                }))
            );
        };
    }

    public static closeEditBannerModal() {
        return (dispatch, getState: () => AppState) => {
            const bannerModalState = Selectors.getEditBannerModal(getState());
            if (bannerModalState.bannerImage) {
                window.URL.revokeObjectURL(bannerModalState.bannerImage);
            }
            dispatch(Actions.cancelUploadBannerImage());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editBannerModal: {
                        ...defaultState.editBannerModal,
                        isOpen: false,
                        bannerImage: null,
                        bannerImageName: null,
                        bannerImageSize: null,
                        bannerImageUploadProgress: null,
                    },
                }))
            );
        };
    }

    public static cancelUploadBannerImage() {
        return (dispatch, getState: () => AppState) => {
            if (Actions.cancelToken) {
                Actions.cancelToken.cancel();
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editBannerModal: {
                        ...prevState.editBannerModal,
                        bannerImageUploadProgress: null,
                    },
                }))
            );
        };
    }

    public static removeBannerImage() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editBannerModal: {
                        ...prevState.editBannerModal,
                        bannerImage: null,
                        bannerImageName: null,
                        bannerImageSize: null,
                        bannerImageUploadProgress: null,
                        bannerImageValidationErrors: null,
                    },
                }))
            );
        };
    }

    public static onDropBannerImage(files: File[]) {
        return (dispatch, getState: () => AppState) => {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = '';
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 1400) {
                        errors = errors + 'Image width should be greater than 1400px. ';
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + 'File size should not exceed 4MB. ';
                    }
                    if (
                        files[0].type.indexOf('jpeg') === -1 &&
                        files[0].type.indexOf('png') === -1 &&
                        files[0].type.indexOf('svg') === -1 &&
                        files[0].type.indexOf('gif') === -1
                    ) {
                        errors = errors + 'Wrong file type. ';
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(
                        Actions.setBannerImage(localImageUrl, files[0].name, files[0].size, errors)
                    );
                };
            };
        };
    }

    public static onChangeBannerImage(event: any) {
        return async (dispatch, getState: () => AppState) => {
            let files = event.target.files;

            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = '';
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 1400) {
                        errors = errors + 'Image width should be greater than 1400px. ';
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + 'File size should not exceed 4MB. ';
                    }
                    if (
                        files[0].type.indexOf('jpeg') === -1 &&
                        files[0].type.indexOf('png') === -1 &&
                        files[0].type.indexOf('svg') === -1 &&
                        files[0].type.indexOf('gif') === -1
                    ) {
                        errors = errors + 'Wrong file type. ';
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(
                        Actions.setBannerImage(localImageUrl, files[0].name, files[0].size, errors)
                    );
                };
            };
        };
    }

    public static setBannerImage(
        image: string,
        name: string,
        size: number,
        validationErrors: string
    ) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        editBannerModal: {
                            ...prevState.editBannerModal,
                            bannerImage: image,
                            bannerImageName: name,
                            bannerImageSize: size,
                            bannerImageValidationErrors: validationErrors,
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static async getCroppedImg(
        imageSrc,
        type,
        pixelCrop,
        rotation = 0,
        flip = { horizontal: false, vertical: false }
    ) {
        const image: any = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return null;
        }
        const rotRad = getRadianAngle(rotation);
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation
        );

        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-image.width / 2, -image.height / 2);
        ctx.fillStyle = '#FFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0);

        const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        ctx.putImageData(data, 0, 0);

        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve(URL.createObjectURL(file));
            }, 'image/png');
        });
    }

    public static onSaveBannerImage(croppedAreaPixels: {
        height: number;
        width: number;
        x: number;
        y: number;
    }) {
        return async (dispatch, getState: () => AppState) => {
            Actions.cancelToken = getCancelTokenSource();

            try {
                const bannerModalState = Selectors.getEditBannerModal(getState());
                dispatch(stateController.setState({ isProcessing: true }));
                const req = await axiosLib({
                    method: 'GET',
                    url: bannerModalState.bannerImage,
                    responseType: 'blob',
                });
                const cropped: any = await Actions.getCroppedImg(
                    bannerModalState.bannerImage,
                    req.data.type,
                    croppedAreaPixels
                );
                const req2 = await axiosLib({ method: 'GET', url: cropped, responseType: 'blob' });

                const res = await StaffProfileService.uploadBackgroundImage(
                    req2.data,
                    bannerModalState.bannerImageName,
                    (progressEvent) => {
                        var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            stateController.setState((prevState) => ({
                                ...prevState,
                                editBannerModal: {
                                    ...prevState.editBannerModal,
                                    bannerImageUploadProgress: percentCompleted,
                                },
                            }))
                        );
                    },
                    Actions.cancelToken.token
                );

                if (res) {
                    dispatch(
                        notificationCreate({
                            message: 'Your banner image was updated successfully',
                            level: 'success',
                        })
                    );
                    dispatch(Actions.onBannerSaved());
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onBannerSaved() {
        return async (dispatch, getState: () => AppState) => {
            await dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditBannerModal());
        };
    }

    // EDIT BANNER MODAL END

    // EDIT PROFILE IMAGE MODAL START

    public static openEditProfileImageModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editProfileImageModal: {
                        ...prevState.editProfileImageModal,
                        isOpen: true,
                    },
                }))
            );
        };
    }

    public static closeEditProfileImageModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editProfileImageModal: {
                        ...prevState.editProfileImageModal,
                        isOpen: false,
                    },
                }))
            );

            const profileImageModalState = Selectors.getEditProfileImageModal(getState());
            if (profileImageModalState.profileImage) {
                window.URL.revokeObjectURL(profileImageModalState.profileImage);
            }
            dispatch(Actions.cancelUploadProfileImage());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editProfileImageModal: {
                        ...defaultState.editProfileImageModal,
                        isOpen: false,
                        profileImage: null,
                        profileImageName: null,
                        profileImageSize: null,
                        profileImageUploadProgress: null,
                    },
                }))
            );
        };
    }

    public static cancelUploadProfileImage() {
        return (dispatch, getState: () => AppState) => {
            if (Actions.cancelToken) {
                Actions.cancelToken.cancel();
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editProfileImageModal: {
                        ...prevState.editProfileImageModal,
                        profileImageUploadProgress: null,
                    },
                }))
            );
        };
    }

    public static removeProfileImage() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editProfileImageModal: {
                        ...prevState.editProfileImageModal,
                        profileImage: null,
                        profileImageName: null,
                        profileImageSize: null,
                        profileImageUploadProgress: null,
                        profileImageValidationErrors: null,
                    },
                }))
            );
        };
    }

    public static onDropProfileImage(files: File[]) {
        return (dispatch, getState: () => AppState) => {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = '';
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 140) {
                        errors = errors + 'Image width should be greater than 140px. ';
                    }
                    if ((e.target as any).height < 140) {
                        errors = errors + 'Image height should be greater than 140px. ';
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + 'File size should not exceed 4MB. ';
                    }
                    if (
                        files[0].type.indexOf('jpeg') === -1 &&
                        files[0].type.indexOf('png') === -1 &&
                        files[0].type.indexOf('svg') === -1 &&
                        files[0].type.indexOf('gif') === -1
                    ) {
                        errors = errors + 'Wrong file type. ';
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(
                        Actions.setProfileImage(localImageUrl, files[0].name, files[0].size, errors)
                    );
                };
            };
        };
    }

    public static onChangeProfileImage(event: any) {
        return async (dispatch, getState: () => AppState) => {
            let files = event.target.files;

            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                let errors = '';
                const imageObj = new Image();
                imageObj.src = e.target.result as any;
                imageObj.onload = (e) => {
                    if ((e.target as any).width < 140) {
                        errors = errors + 'Image width should be greater than 140px. ';
                    }
                    if ((e.target as any).height < 140) {
                        errors = errors + 'Image height should be greater than 140px. ';
                    }
                    if (files[0].size > 4194304) {
                        errors = errors + 'File size should not exceed 4MB. ';
                    }
                    if (
                        files[0].type.indexOf('jpeg') === -1 &&
                        files[0].type.indexOf('png') === -1 &&
                        files[0].type.indexOf('svg') === -1 &&
                        files[0].type.indexOf('gif') === -1
                    ) {
                        errors = errors + 'Wrong file type. ';
                    }

                    const localImageUrl = window.URL.createObjectURL(files[0]);
                    dispatch(
                        Actions.setProfileImage(localImageUrl, files[0].name, files[0].size, errors)
                    );
                };
            };
        };
    }

    public static setProfileImage(
        image: string,
        name: string,
        size: number,
        validationErrors: string
    ) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        editProfileImageModal: {
                            ...prevState.editProfileImageModal,
                            profileImage: image,
                            profileImageName: name,
                            profileImageSize: size,
                            profileImageValidationErrors: validationErrors,
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onSaveProfileImage(croppedAreaPixels: {
        height: number;
        width: number;
        x: number;
        y: number;
    }) {
        return async (dispatch, getState: () => AppState) => {
            Actions.cancelToken = getCancelTokenSource();

            try {
                const profileImageModalState = Selectors.getEditProfileImageModal(getState());
                dispatch(stateController.setState({ isProcessing: true }));
                const req = await axiosLib({
                    method: 'GET',
                    url: profileImageModalState.profileImage,
                    responseType: 'blob',
                });
                const cropped: any = await Actions.getCroppedImg(
                    profileImageModalState.profileImage,
                    req.data.type,
                    croppedAreaPixels
                );
                const req2 = await axiosLib({ method: 'GET', url: cropped, responseType: 'blob' });

                const res = await StaffProfileService.uploadImage(
                    req2.data,
                    profileImageModalState.profileImageName,
                    (progressEvent) => {
                        var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        dispatch(
                            stateController.setState((prevState) => ({
                                ...prevState,
                                editProfileImageModal: {
                                    ...prevState.editProfileImageModal,
                                    profileImageUploadProgress: percentCompleted,
                                },
                            }))
                        );
                    },
                    Actions.cancelToken.token
                );

                if (res) {
                    dispatch(Actions.onProfileImageSaved(cropped));
                    dispatch(
                        notificationCreate({
                            message: 'Your profile image was updated successfully',
                            level: 'success',
                        })
                    );
                }
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onProfileImageSaved(cropped: string) {
        return async (dispatch, getState: () => AppState) => {
            const { personalAccount } = getPersonalAccountUserPermissions(getState());

            dispatch({
                type: PERMISSIONS_PERSONAL_ACCOUNT_UPDATE,
                payload: {
                    data: {
                        ...personalAccount,
                        photo: cropped,
                    },
                },
            });

            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditProfileImageModal());
        };
    }

    // EDIT PROFILE IMAGE MODAL END

    // EDIT NAME MODAL START

    public static openEditNameModal() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editNameModal: {
                        ...defaultState.editNameModal,
                        isOpen: true,
                        firstName: profile?.generalInfo?.firstName,
                        lastName: profile?.generalInfo?.lastName,
                    },
                }))
            );
        };
    }

    public static closeEditNameModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editNameModal: {
                        ...prevState.editNameModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onFirstNameChange(firstName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editNameModal: {
                        ...prevState.editNameModal,
                        firstName: firstName,
                    },
                }))
            );
        };
    }

    public static onLastNameChange(lastName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editNameModal: {
                        ...prevState.editNameModal,
                        lastName: lastName,
                    },
                }))
            );
        };
    }

    public static onSaveProfileName() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const nameModalState = Selectors.getEditNameModal(getState());
                const res = await StaffProfileService.saveName({
                    firstName: nameModalState.firstName,
                    lastName: nameModalState.lastName,
                });

                dispatch(
                    notificationCreate({
                        message: 'Your name was saved successfully',
                        level: 'success',
                    })
                );
                dispatch(Actions.onNameSaved(nameModalState.firstName, nameModalState.lastName));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onNameSaved(firstName: string, lastName: string) {
        return async (dispatch, getState: () => AppState) => {
            const { personalAccount } = getPersonalAccountUserPermissions(getState());

            dispatch({
                type: PERMISSIONS_PERSONAL_ACCOUNT_UPDATE,
                payload: {
                    data: {
                        ...personalAccount,
                        firstName: firstName,
                        lastName: lastName,
                    },
                },
            });

            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditNameModal());
        };
    }

    // EDIT NAME MODAL END

    // EDIT JOB TITLE MODAL START

    public static openEditJobTitleModal() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editJobTitleModal: {
                        ...defaultState.editJobTitleModal,
                        isOpen: true,
                        team: profile?.generalInfo?.squad?.name,
                        squadId: profile?.generalInfo?.squad?.id,
                        jobTitle: profile?.generalInfo?.jobTitle,
                    },
                }))
            );
        };
    }

    public static closeEditJobTitleModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editJobTitleModal: {
                        ...prevState.editJobTitleModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onJobTitleChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editJobTitleModal: {
                        ...prevState.editJobTitleModal,
                        jobTitle: value,
                    },
                }))
            );
        };
    }

    public static onSaveJobTitle() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const jobTitleModalState = Selectors.getEditJobTitleModal(getState());
                const res = await StaffProfileService.savePosition({
                    jobTitle: jobTitleModalState.jobTitle,
                });

                dispatch(
                    notificationCreate({
                        message: 'Your job title was updated successfully',
                        level: 'success',
                    })
                );
                dispatch(Actions.onJobTitleSaved());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onJobTitleSaved() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditJobTitleModal());
        };
    }

    // EDIT JOB TITLE MODAL END

    // EDIT AGE MODAL START

    public static openEditAgeModal() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editAgeModal: {
                        ...defaultState.editAgeModal,
                        isOpen: true,
                        birthDate: profile.generalInfo.birthDate,
                        isAgeVisible: profile.generalInfo.isAgeVisible,
                    },
                }))
            );
        };
    }

    public static closeEditAgeModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editAgeModal: {
                        ...prevState.editAgeModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onBirthDateChange(date: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editAgeModal: {
                        ...prevState.editAgeModal,
                        birthDate: date,
                    },
                }))
            );
        };
    }

    public static onShowAgeToggle(value: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editAgeModal: {
                        ...prevState.editAgeModal,
                        isAgeVisible: value,
                    },
                }))
            );
        };
    }

    public static onSaveAge() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const ageModalState = Selectors.getEditAgeModal(getState());
                const res = await StaffProfileService.saveAge({
                    birthDate: convertToUtcDate(ageModalState.birthDate).toDate(),
                    isAgeVisible: ageModalState.isAgeVisible,
                });

                dispatch(
                    notificationCreate({
                        message: 'Your age was updated successfully',
                        level: 'success',
                    })
                );
                dispatch(Actions.onAgeSaved());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onAgeSaved() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditAgeModal());
        };
    }

    // EDIT AGE MODAL END

    // EDIT ABOUT MODAL START

    public static openEditAboutModal() {
        return (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editAboutModal: {
                        ...defaultState.editAboutModal,
                        isOpen: true,
                        aboutText: profile?.about ? profile.about : '',
                    },
                }))
            );
        };
    }

    public static closeEditAboutModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editAboutModal: {
                        ...prevState.editAboutModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onAboutTextChange(aboutText: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editAboutModal: {
                        ...prevState.editAboutModal,
                        aboutText: aboutText,
                    },
                }))
            );
        };
    }

    public static onSaveAbout() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const aboutModalState = Selectors.getEditAboutModal(getState());
                const res = await StaffProfileService.saveAboutText({
                    about: aboutModalState.aboutText.trim(),
                });

                dispatch(
                    notificationCreate({
                        message: 'Your about section was saved successfully',
                        level: 'success',
                    })
                );
                dispatch(Actions.onAboutSaved());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onAboutSaved() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditAboutModal());
        };
    }

    // EDIT ABOUT MODAL END

    // EDIT PERSONAL SUMMARY MODAL START

    public static getNationalitiesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CommonService.getNationalitiesList();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        nationalitiesList: data.map((item) => ({
                            ...item,
                            ico: item.flagPath,
                            value: item.nationalityName,
                            label: item.nationalityName,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static getLanguagesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CommonService.getLanguages();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        languagesList: data.map((item) => ({
                            ...item,
                            value: item.name,
                            label: item.name,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static getLocationsList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await AreaService.getCountries();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        locationsList: items.map((item) => ({
                            ...item,
                            value: item.name,
                            label: item.name,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static openEditPersonalSummaryModal() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const profile = Selectors.getProfile(getState());

                const currentNationality = profile?.nationalities.map((item) => ({
                    ...item,
                    value: item.nationalityName,
                    label: item.nationalityName,
                    ico: item.flagPath,
                }));

                const currentLanguages = profile?.languages.map((item) => ({
                    ...item,
                    value: item.name,
                    label: item.name,
                }));

                const currentLocations = profile?.preferredAreas.map((item) => ({
                    ...item,
                    value: item.name,
                    label: item.name,
                }));

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        editPersonalSummaryModal: {
                            ...defaultState.editPersonalSummaryModal,
                            isOpen: true,
                            nationality: currentNationality,
                            languages: currentLanguages,
                            locations: currentLocations,
                        },
                    }))
                );
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static closeEditPersonalSummaryModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editPersonalSummaryModal: {
                        ...prevState.editPersonalSummaryModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onNationalityChange(selected: NationalitySelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editPersonalSummaryModal: {
                        ...prevState.editPersonalSummaryModal,
                        nationality: selected,
                    },
                }))
            );
        };
    }

    public static onLanguagesChange(selected: LanguagesSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editPersonalSummaryModal: {
                        ...prevState.editPersonalSummaryModal,
                        languages: selected,
                    },
                }))
            );
        };
    }

    public static onLocationsChange(selected: LocationsSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editPersonalSummaryModal: {
                        ...prevState.editPersonalSummaryModal,
                        locations: selected,
                    },
                }))
            );
        };
    }

    public static onSavePersonalSummary() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const aboutModalState = Selectors.getEditPersonalSummaryModal(getState());
                const res = await StaffProfileService.savePersonalSummary({
                    nationalityAreaIds: aboutModalState.nationality.map((item) => item.id),
                    languageIds: aboutModalState.languages.map((item) => item.id),
                    preferredAreaIds: aboutModalState.locations.map((item) => item.id),
                });

                dispatch(
                    notificationCreate({
                        message: 'Your personal summary was saved successfully',
                        level: 'success',
                    })
                );
                dispatch(Actions.onPersonalSummarySaved());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onPersonalSummarySaved() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditPersonalSummaryModal());
        };
    }

    // EDIT PERSONAL SUMMARY MODAL END

    // EDIT HIGHLIGHTS MODAL START

    public static openEditHighlightsModal() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());

            const highlightsDeepCopy = cloneDeep(profile.highlights);

            const highlightsInitial =
                highlightsDeepCopy.length === 0 ? [] : [...highlightsDeepCopy];

            await dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editHighlightsModal: {
                        ...defaultState.editHighlightsModal,
                        isOpen: true,
                        highlights: highlightsInitial,
                    },
                }))
            );
        };
    }

    public static closeEditHighlightsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editHighlightsModal: {
                        ...prevState.editHighlightsModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onHighlightTitleChange(index: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());

            highlightsState[index].title = value;

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editHighlightsModal: {
                        ...prevState.editHighlightsModal,
                        highlights: [...highlightsState],
                    },
                }))
            );
        };
    }

    public static onHighlightDescriptionChange(index: number, value: string) {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());

            highlightsState[index].description = value;

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editHighlightsModal: {
                        ...prevState.editHighlightsModal,
                        highlights: [...highlightsState],
                    },
                }))
            );
        };
    }

    public static createNewHighlight() {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());
            const newHighlight = {
                id: null,
                title: '',
                description: '',
            };

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editHighlightsModal: {
                        ...prevState.editHighlightsModal,
                        highlights: [...highlightsState, newHighlight],
                    },
                }))
            );
        };
    }

    public static deleteHighlight(index: number) {
        return (dispatch, getState: () => AppState) => {
            const highlightsState = Selectors.getHighlights(getState());
            highlightsState.splice(index, 1);

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editHighlightsModal: {
                        ...prevState.editHighlightsModal,
                        highlights: [...highlightsState],
                    },
                }))
            );
        };
    }

    public static onSaveHighlights() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const highlightsState = Selectors.getHighlights(getState());

                const requestData = highlightsState
                    .filter((x) => !!x.title && !!x.description)
                    .map((item) => ({
                        id: item.id ? item.id : null,
                        title: item.title.trim(),
                        description: item.description.trim(),
                    }));

                const res = await StaffProfileService.saveHighlights({
                    highlights: requestData,
                });

                dispatch(
                    notificationCreate({
                        message: 'Your highlights were updated successfully',
                        level: 'success',
                    })
                );
                dispatch(Actions.onHighlightsSaved());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onHighlightsSaved() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditHighlightsModal());
        };
    }

    // EDIT HIGHLIGHTS MODAL END

    // EDIT KEY SKILLS MODAL START

    public static getCoachingLicensesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await StaffProfileService.getCoachingLicenses();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        coachingLicensesList: items.map((item) => ({
                            ...item,
                            value: item.name,
                            label: item.name,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static getSkillsList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const { items } = await StaffProfileService.getSkills();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        skillsList: items.map((item) => ({
                            ...item,
                            value: item.name,
                            label: item.name,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static openEditKeySkillsModal() {
        return async (dispatch, getState: () => AppState) => {
            const profile = Selectors.getProfile(getState());
            const coachingLicensesList = Selectors.getCoachingLicensesList(getState());

            const currentCoachingLicense = coachingLicensesList?.find(
                (item) => item.id === profile?.coachingLicense?.id
            );

            const currentQualifications = profile?.qualifications.map((item) => ({
                ...item,
                value: item.name,
                label: item.name,
            }));

            const currentSkills = profile?.skills.map((item) => ({
                ...item,
                value: item.name,
                label: item.name,
            }));

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editKeySkillsModal: {
                        ...defaultState.editKeySkillsModal,
                        isOpen: true,
                        coachingLicense: currentCoachingLicense ? currentCoachingLicense : null,
                        qualifications: currentQualifications,
                        skills: currentSkills,
                    },
                }))
            );
        };
    }

    public static closeEditKeySkillsModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editKeySkillsModal: {
                        ...prevState.editKeySkillsModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onCoachingLicenseChange(selected: CoachingLicensesSelectItem) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editKeySkillsModal: {
                        ...prevState.editKeySkillsModal,
                        coachingLicense: selected,
                    },
                }))
            );
        };
    }

    public static onQualificationsChange(selected: QualificationSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editKeySkillsModal: {
                        ...prevState.editKeySkillsModal,
                        qualifications: selected,
                    },
                }))
            );
        };
    }

    public static onSkillsChange(selected: SkillsSelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editKeySkillsModal: {
                        ...prevState.editKeySkillsModal,
                        skills: selected,
                    },
                }))
            );
        };
    }

    public static onSaveKeySkills() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const keySkillsModalState = Selectors.getEditKeySkillsModal(getState());
                const res = await StaffProfileService.saveKeySkills({
                    coachLicenseId: keySkillsModalState.coachingLicense?.id ?? null,
                    qualifications: keySkillsModalState.qualifications.map((item) => ({
                        name: item.name,
                    })),
                    skillIds: keySkillsModalState.skills.map((item) => item.id),
                });

                dispatch(
                    notificationCreate({
                        message: 'Your key skills were saved successfully',
                        level: 'success',
                    })
                );
                dispatch(Actions.onKeySkillsSaved());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onKeySkillsSaved() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditKeySkillsModal());
        };
    }

    // EDIT KEY SKILLS MODAL END

    // EDIT CAREER MODAL START

    public static loadProfileCareerDraft = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const { items } = await StaffProfileService.getProfileCareerDraft();

                const itemsExtended = items.map((item, idx) => ({
                    ...item,
                    key: `career-item-${idx}`,
                    isCurrentlyWork: !item.toDate || getIsDateInFuture(item.toDate),
                }));

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        editCareerModal: {
                            ...prevState.editCareerModal,
                            careerCurrent: [...itemsExtended],
                            careerChanged: cloneDeep(itemsExtended),
                        },
                    }))
                );
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    };

    public static openEditCareerModal(addCareerItem: boolean) {
        return (dispatch, getState: () => AppState) => {
            let newCareerItem: StaffCareerDraftItemDtoExtended;
            if (addCareerItem) {
                newCareerItem = {
                    id: null,
                    role: '',
                    fromDate: null,
                    toDate: null,
                    squad: null,
                    clubStaffHistoryId: null,
                    isCurrentlyWork: false,
                };
            } else {
                newCareerItem = null;
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editCareerModal: {
                        ...defaultState.editCareerModal,
                        isOpen: true,
                        newCareerItem: newCareerItem,
                    },
                }))
            );

            dispatch(Actions.loadProfileCareerDraft());
        };
    }

    public static closeEditCareerModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editCareerModal: {
                        ...prevState.editCareerModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onEditCareerItem = (
        field: string,
        value: string | boolean,
        itemChanged: StaffCareerDraftItemDtoExtended
    ) => {
        return (dispatch, getState: () => AppState) => {
            const careerChanged = Selectors.getEditCareerModal(getState()).careerChanged;

            if (!!itemChanged) {
                careerChanged.find((item) => item === itemChanged)[field] = value;
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        editCareerModal: {
                            ...prevState.editCareerModal,
                            careerChanged: [...careerChanged],
                        },
                    }))
                );
            } else {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        editCareerModal: {
                            ...prevState.editCareerModal,
                            newCareerItem: {
                                ...prevState.editCareerModal.newCareerItem,
                                [field]: value,
                            },
                        },
                    }))
                );
            }
        };
    };

    public static onSaveEditCareer() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const careerModalState = Selectors.getEditCareerModal(getState());
                const careerChanged = careerModalState.careerChanged;
                const newCareerItem = careerModalState.newCareerItem;

                const careerData =
                    newCareerItem !== null && Selectors.isNewCareerItemValid(getState())
                        ? [newCareerItem, ...careerChanged]
                        : [...careerChanged];

                const preparedData: SaveProfileCareerItem[] = careerData.map((item) => ({
                    id: item.id,
                    role: item.role,
                    fromDate: convertToUtcDate(item.fromDate).toDate(),
                    toDate: item.toDate ? convertToUtcDate(item.toDate).toDate() : null,
                    squadId: item.squad.id,
                    clubStaffHistoryId: item.clubStaffHistoryId,
                }));

                const res = await StaffProfileService.saveCareer({ items: preparedData });

                dispatch(
                    notificationCreate({
                        message: 'Changes to career will be reviewed',
                        level: 'success',
                    })
                );
                dispatch(Actions.onCareerSaved());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }

    public static onCareerSaved() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.loadCurrentProfile());
            dispatch(Actions.closeEditCareerModal());
        };
    }

    // EDIT CAREER MODAL END

    // EDIT CAREER CONFIRM MODAL START

    public static openEditCareerCancelAlertModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editCareerCancelAlertModal: {
                        ...defaultState.editCareerCancelAlertModal,
                        isOpen: true,
                    },
                }))
            );
        };
    }

    public static discardEditCareerCancelAlertModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editCareerCancelAlertModal: {
                        ...prevState.editCareerCancelAlertModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static confirmEditCareerCancelAlertModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.closeEditCareerModal());
            dispatch(Actions.discardEditCareerCancelAlertModal());
        };
    }

    // EDIT CAREER CONFIRM MODAL END

    // EDIT CAREER DELETE MODAL START

    public static openEditCareerDeleteModal(itemChanged: StaffCareerDraftItemDtoExtended) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editCareerDeleteModal: {
                        ...defaultState.editCareerDeleteModal,
                        isOpen: true,
                        deleteItem: itemChanged,
                    },
                }))
            );
        };
    }

    public static closeEditCareerDeleteModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editCareerDeleteModal: {
                        ...prevState.editCareerDeleteModal,
                        isOpen: false,
                    },
                }))
            );
        };
    }

    public static onEditCareerDelete() {
        return (dispatch, getState: () => AppState) => {
            const editCareerModalState = Selectors.getEditCareerModal(getState());
            const careerChanged = editCareerModalState.careerChanged;
            const deleteItem = Selectors.getEditCareerDeleteModal(getState()).deleteItem;
            const indexToRemove = editCareerModalState.careerChanged.findIndex(
                (item) => item === deleteItem
            );

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    editCareerModal: {
                        ...prevState.editCareerModal,
                        careerChanged: [
                            ...careerChanged.filter((_, index) => index !== indexToRemove),
                        ],
                    },
                }))
            );

            dispatch(Actions.closeEditCareerDeleteModal());
        };
    }

    // EDIT CAREER DELETE MODAL END
}

class Selectors {
    public static getRoot = (state: AppState): State => state.staffProfile;
    public static getProfile = (state: AppState) => Selectors.getRoot(state).profile;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static isOwnProfile = (state: AppState) => {
        const isPersonalAccount = getIsPersonalAccount(state);
        const { isOwnProfile } = Selectors.getRoot(state);
        return isPersonalAccount && isOwnProfile;
    };
    public static isViewOwnProfileByStaffId = (state: AppState) => {
        const auth = state.auth;
        const isPersonalAccount = getIsPersonalAccount(state);
        const { profile } = Selectors.getRoot(state);

        return isPersonalAccount && auth?.staffId === profile?.staffId;
    };

    public static getEditBannerModal = (state: AppState) =>
        Selectors.getRoot(state).editBannerModal;
    public static getEditProfileImageModal = (state: AppState) =>
        Selectors.getRoot(state).editProfileImageModal;
    public static getEditNameModal = (state: AppState) => Selectors.getRoot(state).editNameModal;
    public static getEditJobTitleModal = (state: AppState) =>
        Selectors.getRoot(state).editJobTitleModal;
    public static getEditAgeModal = (state: AppState) => Selectors.getRoot(state).editAgeModal;
    public static getEditAboutModal = (state: AppState) => Selectors.getRoot(state).editAboutModal;
    public static getEditPersonalSummaryModal = (state: AppState) =>
        Selectors.getRoot(state).editPersonalSummaryModal;
    public static getEditHighlightsModal = (state: AppState) =>
        Selectors.getRoot(state).editHighlightsModal;
    public static getEditKeySkillsModal = (state: AppState) =>
        Selectors.getRoot(state).editKeySkillsModal;
    public static getEditCareerModal = (state: AppState) =>
        Selectors.getRoot(state).editCareerModal;
    public static getEditCareerCancelAlertModal = (state: AppState) =>
        Selectors.getRoot(state).editCareerCancelAlertModal;
    public static getEditCareerDeleteModal = (state: AppState) =>
        Selectors.getRoot(state).editCareerDeleteModal;

    public static getNationalitiesList = (state: AppState) =>
        Selectors.getRoot(state).nationalitiesList;
    public static getLanguagesList = (state: AppState) => Selectors.getRoot(state).languagesList;
    public static getLocationsList = (state: AppState) => Selectors.getRoot(state).locationsList;
    public static getCoachingLicensesList = (state: AppState) =>
        Selectors.getRoot(state).coachingLicensesList;
    public static getSkillsList = (state: AppState) => Selectors.getRoot(state).skillsList;

    public static isFirstNameValid = (state: AppState) => {
        return (
            !!Selectors.getEditNameModal(state).firstName &&
            !!Selectors.getEditNameModal(state).firstName?.trim()
        );
    };
    public static isLastNameValid = (state: AppState) => {
        return (
            !!Selectors.getEditNameModal(state).lastName &&
            !!Selectors.getEditNameModal(state).lastName?.trim()
        );
    };
    public static isEditNameModalDataInvalid = (state: AppState) => {
        return !Selectors.isFirstNameValid(state) || !Selectors.isLastNameValid(state);
    };
    public static isEditNameModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const editNameModal = Selectors.getEditNameModal(state);
        return (
            profile?.generalInfo?.firstName !== editNameModal.firstName ||
            profile?.generalInfo?.lastName !== editNameModal.lastName
        );
    };
    public static isEditAboutModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const aboutTextServer = profile?.about ?? '';
        const editAboutModal = Selectors.getEditAboutModal(state);
        const aboutTextModal = editAboutModal.aboutText ?? '';
        return aboutTextServer !== aboutTextModal;
    };

    public static getHighlights = (state: AppState) =>
        Selectors.getEditHighlightsModal(state).highlights;
    public static isEditHighlightsModalDataValid = (state: AppState) => {
        const highlightsValidation = Selectors.highlightsModalValidation(state);

        return highlightsValidation.every((x) => x.isTitleSpecified && x.isDescriptionSpecified);
    };
    public static highlightsModalValidation = (state: AppState): HighlightsModalValidation[] => {
        const highlights = Selectors.getHighlights(state);

        return highlights.map((item) => {
            const isTitleSpecified = !!item.title?.trim();
            const isDescriptionSpecified = !!item.description?.trim();
            return {
                isTitleSpecified: isTitleSpecified,
                isDescriptionSpecified: isDescriptionSpecified,
            };
        });
    };
    public static isEditHighlightsModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const highlightsServer = profile?.highlights ?? [];
        const highlightsModal = Selectors.getHighlights(state);

        return !Helpers.isArraysEqual(highlightsServer, highlightsModal);
    };

    public static isEditAgeModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const ageModal = Selectors.getEditAgeModal(state);
        const serverBirthDate = profile?.generalInfo?.birthDate
            ? new Date(profile?.generalInfo?.birthDate).toISOString()
            : null;
        const localBirthDate = ageModal?.birthDate
            ? new Date(ageModal.birthDate).toISOString()
            : null;

        return (
            serverBirthDate !== localBirthDate ||
            profile?.generalInfo?.isAgeVisible !== ageModal.isAgeVisible
        );
    };

    public static isNationalityValid = (state: AppState) =>
        Selectors.getEditPersonalSummaryModal(state).nationality.length > 0;
    public static isEditPersonalSummaryModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const personalSummaryModal = Selectors.getEditPersonalSummaryModal(state);

        const serverNationality = profile?.nationalities?.map((item) => item.id);
        const localNationality = personalSummaryModal?.nationality?.map((item) => item.id);
        const serverLanguages = profile?.languages?.map((item) => item.id);
        const localLanguages = personalSummaryModal?.languages?.map((item) => item.id);
        const serverLocations = profile?.preferredAreas?.map((item) => item.id);
        const localLocations = personalSummaryModal?.locations?.map((item) => item.id);

        return (
            !Helpers.isArraysEqual(serverNationality, localNationality) ||
            !Helpers.isArraysEqual(serverLanguages, localLanguages) ||
            !Helpers.isArraysEqual(serverLocations, localLocations)
        );
    };

    public static isEditJobTitleModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const jobTitleModal = Selectors.getEditJobTitleModal(state);

        return profile?.generalInfo?.jobTitle !== jobTitleModal?.jobTitle;
    };

    public static isJobTitleValid = (state: AppState) => {
        const jobTitleModal = Selectors.getEditJobTitleModal(state);
        return !!jobTitleModal.jobTitle;
    };

    public static isEditKeySkillsModalDataChanged = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const keySkillsModal = Selectors.getEditKeySkillsModal(state);

        const serverQualifications = profile?.qualifications?.map((item) => item.name);
        const localQualifications = keySkillsModal.qualifications?.map((item) => item.name);
        const serverSkills = profile?.skills?.map((item) => item.id);
        const localSkills = keySkillsModal?.skills?.map((item) => item.id);

        return (
            !Helpers.isArraysEqual(serverQualifications, localQualifications) ||
            !Helpers.isArraysEqual(serverSkills, localSkills) ||
            profile?.coachingLicense?.id !== keySkillsModal?.coachingLicense?.id
        );
    };

    public static isKeySkillsWidgetVisible = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const isOwnProfile = Selectors.isOwnProfile(state);
        const skills = profile?.skills && profile?.skills.length > 0 ? profile?.skills : null;
        const qualifications = profile?.coachingLicense
            ? [profile?.coachingLicense, ...(profile?.qualifications ?? [])]
            : profile?.qualifications && profile?.qualifications.length > 0
              ? profile?.qualifications
              : null;

        return isOwnProfile || !!skills || !!qualifications;
    };

    public static isHighlightWidgetVisible = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const isOwnProfile = Selectors.isOwnProfile(state);
        return isOwnProfile || (profile?.highlights && profile?.highlights.length > 0);
    };

    public static isPersonalSummaryWidgetVisible = (state: AppState) => {
        const profile = Selectors.getProfile(state);
        const isOwnProfile = Selectors.isOwnProfile(state);
        const nationalities = profile?.nationalities && profile?.nationalities.length > 0;
        const languages = profile?.languages && profile?.languages.length > 0;
        const preferredAreas = profile?.preferredAreas && profile?.preferredAreas.length > 0;
        return isOwnProfile || nationalities || languages || preferredAreas;
    };

    public static isNewCareerItemChanged = (state: AppState) => {
        const newCareerItem = Selectors.getEditCareerModal(state).newCareerItem;

        if (newCareerItem === null) {
            return false;
        }

        return (
            JSON.stringify({
                role: '',
                fromDate: null,
                toDate: null,
                squad: null,
            }) !==
            JSON.stringify({
                role: newCareerItem.role,
                fromDate: newCareerItem.fromDate,
                toDate: newCareerItem.toDate,
                squad: newCareerItem.squad,
            })
        );
    };

    public static isEditCareerModalDataChanged = (state: AppState) => {
        const careerCurrent = Selectors.getEditCareerModal(state)?.careerCurrent;
        const careerChanged = Selectors.getEditCareerModal(state).careerChanged;

        return !Helpers.isArraysEqual(careerCurrent, careerChanged);
    };

    public static isEditCareerModalDataValid = (state: AppState) => {
        const careerChanged = Selectors.getEditCareerModal(state).careerChanged;
        return careerChanged.every((item) => {
            return item.isCurrentlyWork
                ? Helpers.isCurrentExperienceValid(item)
                : Helpers.isExperienceValid(item);
        });
    };

    public static isNewCareerItemValid = (state: AppState) => {
        const newCareerItem = Selectors.getEditCareerModal(state).newCareerItem;

        if (newCareerItem === null) {
            return true;
        }

        return newCareerItem.isCurrentlyWork
            ? Helpers.isCurrentExperienceValid(newCareerItem)
            : Helpers.isExperienceValid(newCareerItem);
    };
}

class Helpers {
    public static isArraysEqual = (arr1: any[], arr2: any[]) => {
        if (!arr1 || !arr2) return false;
        if (arr1.length !== arr2.length) return false;
        return arr1.every((obj, index) => JSON.stringify(obj) === JSON.stringify(arr2[index]));
    };

    public static isCurrentExperienceValid = (careerItem: StaffCareerDraftItemDtoExtended) => {
        return (
            !!careerItem.role &&
            !!careerItem.role.trim() &&
            careerItem.role.trim().length <= ROLE_MAX_LENGTH &&
            !!careerItem.fromDate &&
            !!careerItem.squad?.id &&
            (!careerItem?.toDate || moment(careerItem?.toDate) >= moment().endOf('day'))
        );
    };

    public static isExperienceValid = (careerItem: StaffCareerDraftItemDtoExtended) => {
        return (
            !!careerItem.role &&
            !!careerItem.role.trim() &&
            careerItem.role.trim().length <= ROLE_MAX_LENGTH &&
            !!careerItem.fromDate &&
            !!careerItem.squad?.id &&
            !!careerItem.toDate &&
            moment(careerItem?.toDate) <= moment().endOf('day')
        );
    };
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
